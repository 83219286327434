/// <reference lib="dom" />
import m, {
    Children,
    ClassComponent,
    Vnode,
  } from "https://esm.sh/mithril@2.2.2";
  import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";
  // Render markdown using the default renderer
  import { Renderer } from "jsr:@libs/markdown@^1.2.2";
  
  import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
  import qs from "https://esm.sh/qr-scanner@1.4.2";
  import state from "../state.ts";
  
  
  export interface ScanViewAttrs {
    token?: string;
  }
  
  export class ScanView implements ClassComponent<ScanViewAttrs> {
    markdown: string = "";
    videoElem!:HTMLVideoElement;
    qrScanner!:qs;
    oncreate(vnode: m.VnodeDOM<ScanViewAttrs, this>) {
        this.videoElem = vnode.dom.getElementsByTagName("video")[0] as HTMLVideoElement;
        this.qrScanner = new qs(
            this.videoElem,
            result => console.log('decoded qr code:', result),
            { maxScansPerSecond: 4,
                highlightScanRegion: true,
                highlightCodeOutline: true,
                returnDetailedScanResult: true  },
        );
        this.qrScanner.start();
    }
    onbeforeremove(_vnode: m.VnodeDOM<ScanViewAttrs, this>): Promise<any> | void {
       this.qrScanner.stop();
       this.qrScanner.destroy();
    }
     view(_vnode: Vnode<ScanViewAttrs, this>): Children {
      return m(".viewarea", {}, [
        m("video.w-full", {}),
      ]);
    }
  }
  