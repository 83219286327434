/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";
import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";
// Render markdown using the default renderer
import { Renderer } from "jsr:@libs/markdown@^1.2.2";

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
import state from "../state.ts";


export interface InfoViewAttrs {
  token: string;
}

export class InfoView implements ClassComponent<InfoViewAttrs> {
  markdown: string = "";
  oninit(vnode: m.Vnode<InfoViewAttrs, this>) {
    if (vnode.attrs.token != "") {
      state.queryDataForToken(vnode.attrs.token);
    }
  }
   view(vnode: Vnode<InfoViewAttrs, this>): Children {
    const fd = state.formData();
    let t:string = i18next.t("info.template")
    t = t.replace("{m}", fd.employeeName)
    t = t.replace("{t}", fd.attending ? i18next.t("info.attending.yes") : i18next.t("info.attending.no")),
    t = t.replace("{g}", fd.guest ? `**${fd.guest.name}**` : i18next.t("info.nobody"))
    if(fd.children.length == 0){
      t = t.replace("{k}", i18next.t("info.nochildren"))  
    }else{
      const k = fd.children.map((k) => { return k.name}).join(', ')
      t = t.replace("{k}", `**${i18next.t("info.children")} ${k}**` )
    }
    if(fd.extraguests.length == 0){
      t = t.replace("{xg}", i18next.t("info.noextras"))  
    }else{
      const xg = fd.extraguests.map((k) => { return k.name}).join(', ')
      t = t.replace("{xg}", `${i18next.t("info.extras")} **${xg}**` )
    }
    t = t.replace("{l}", `${window.location.origin}/!#/form/${fd.id}`)
    t = t.replace("{a}", `${window.location.origin}/!#/form/${fd.id}`)
    Renderer.render(t).then((value: string) => {
      this.markdown = value;
      m.redraw();
    }).catch((reason: string) => {
      this.markdown = reason;
    });
    return m(".viewarea", {}, [
        m("div.welcome", {}, m.trust(this.markdown)),
      m("div.buttons", {}, [
        
      ]),
    ]);
  }
}
