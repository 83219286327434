/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";
import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
import { TextInput } from "../components/controls/Input.ts";
import state from "../state.ts";
import { DataTable } from "npm:simple-datatables";
import { Fact } from "../components/controls/Card.ts";

export interface LoginViewAttrs {
  email: string;
}

export class LoginView implements ClassComponent<LoginViewAttrs> {
  password: string = "";
  email: string = "";
  data: Object = {};
  oninit(vnode: m.Vnode<LoginViewAttrs, this>) {
    if (vnode.attrs.email != "") {
      //state.queryDataForToken(vnode.attrs.email);
      console.log(vnode.attrs.email);
    }
    if (state.isAuthenticated()) {
      fetch("/export", {
        headers: { Authorization: state.getToken() },
      }).then((response) => {
        response.json().then((response) => {
          this.data = response;
          console.log(this.data)
          m.redraw();
        });
      }).catch((reason) => {
        console.error(reason);
      });
    }
  }
  view(_vnode: Vnode<LoginViewAttrs, this>): Children {
    return m(".viewarea", {}, [
      m("form.max-w-md.space-y-3.mx-auto", [
        m("div.h-10.w-10"),
        state.isAuthenticated() ? undefined : m(".max-w-md.space-y-3", [
          m(TextInput, {
            type: "email",
            label: i18next.t("form.email.label"),
            placeholder: i18next.t("form.email.placeholder"),
            value: "",
            required: true,
            id: "email",
            autocomplete: "username",
            help: i18next.t("form.email.help"),
            oninput: (event: InputEvent) => {
              this.email = (event.target as HTMLInputElement).value;
            },
          }),
          m(TextInput, {
            type: "password",
            label: i18next.t("form.password.label"),
            value: "",
            required: true,
            id: "password",
            autocomplete: "current-password",
            help: i18next.t("form.password.help"),
            oninput: (event: InputEvent) => {
              this.password = (event.target as HTMLInputElement).value;
            },
          }),
        ]),
        m("div.buttons", {}, [
          state.isAuthenticated()
            ? m(Button, {
              type: ButtonType.Red,
              size: ButtonSize.SizeXl,
              onclick: (_event: MouseEvent) => {
                state.logout();
              },
            }, "Logout")
            : m(Button, {
              size: ButtonSize.SizeXl,
              onclick: (_event: MouseEvent) => {
                state.login(this.email, this.password);
              },
            }, i18next.t("main.login")),
        ]),
      ]),
      state.isAuthenticated()
        ? m(".max-w-md.space-y-3.mx-auto", {}, [
          m(Fact, { number: this.data.replys, label: "Rückmeldungen" }),
          m(Fact, { number: this.data.notAttendingEmployees, label: "Absagen" }),
          m(Fact, { number: this.data.attendingEmployees + this.data.attendingChildren + this.data.attendingGuests , label: "Tickes MA/Gast/Kinder  " }),
          m(Fact, { number: this.data.requestedExtraGuests, label: "Extra Ticket Anfragen" }),
        ])
        : undefined,
    ]);
  }
}
