/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

/*
<div class="flex justify-between mb-1">
  <span class="text-base font-medium text-blue-700 dark:text-white">Label</span>
  <span class="text-sm font-medium text-blue-700 dark:text-white">45%</span>
</div>
<div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div class="bg-blue-600 h-2.5 rounded-full" style="width: 45%"></div>
</div>
*/

export enum ProgressType {
  Default = "bar-only",
  BarProgress = "bar-progress",
  BarLabel = "bar-label",
  BarLabelProgress = "bar-label-progress",
}

export enum ProgressSize {
  SizeSm = "size-sm",
  SizeMd = "size-md",
  SizeLg = "size-lg",
  SizeXl = "size-xl",
}

export enum ProgressColor {
  Default = "color-default",
  Dark = "color-dark",
  Red = "color-red",
  Blue = "color-blue",
  Green = "color-green",
  Yellow = "color-yellow",
  Indigo = "color-indigo",
  Purple = "color-purple",
  Pink = "color-pink",
}

export interface ProgressAttrs {
  type?: ProgressType;
  title?: string;
  size?: ProgressSize;
  color?: ProgressColor;
  progress: number;
  disabled?: boolean;
  onclick?: (event: MouseEvent) => undefined;
}
/**
 * A Progressbar
 */
export class ProgressBar implements ClassComponent<ProgressAttrs> {
  view(vnode: Vnode<ProgressAttrs, this>): Children {
    let progress = 0.0;
    let size = ProgressSize.SizeMd;
    const progressOpts: m.Attributes = {
      class: "progress",
    };
    if (vnode.attrs.type) {
      progressOpts.class += " " + vnode.attrs.type;
    } else {
      progressOpts.class += " " + ProgressType.Default;
    }
    if (vnode.attrs.color) {
      progressOpts.class += " " + vnode.attrs.color;
    } else {
      progressOpts.class += " " + ProgressColor.Default;
    }
    if (vnode.attrs.size) {
      size = vnode.attrs.size;
    }
    if (vnode.attrs.type == ProgressType.BarProgress) {
      if (size == ProgressSize.SizeMd || size == ProgressSize.SizeSm) {
        size = ProgressSize.SizeLg;
      }
    }
    progressOpts.class += " " + size;

    if (vnode.attrs.progress) {
      progress = vnode.attrs.progress * 100.0;
    }

    if (vnode.attrs.onclick) {
      progressOpts["onclick"] = vnode.attrs.onclick;
    }

    /*
<div class="flex justify-between mb-1">
  <span class="text-base font-medium text-blue-700 dark:text-white">Label</span>
  <span class="text-sm font-medium text-blue-700 dark:text-white">45%</span>
</div>
      */
    const labelElem = m(".lbl", [
      m("span.title", vnode.attrs.title),
      vnode.attrs.type == ProgressType.BarLabelProgress
        ? m("span.progress", `${progress.toFixed(0)}%`)
        : undefined,
    ]);

    return m("div", progressOpts, [
      vnode.attrs.type == ProgressType.BarLabel ||
        vnode.attrs.type == ProgressType.BarLabelProgress
        ? labelElem
        : undefined,
      m(
        ".bar",
        m(
          ".fill",
          {
            style: `width: ${progress.toFixed(2)}%;`,
          },
          vnode.attrs.type == ProgressType.BarProgress
            ? `${progress.toFixed(0)}%`
            : "",
        ),
      ),
    ]);
  }
}
