/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";
import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";
// Render markdown using the default renderer
import { Renderer } from "jsr:@libs/markdown@^1.2.2";

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
import { TextInput } from "../components/controls/Input.ts";

export interface ResendLinkViewAttrs {
  email: string;
}

export class ResendLinkView implements ClassComponent<ResendLinkViewAttrs> {
  markdown: string = "";
  email: string = "";
  sent: boolean = false;
  oninit(vnode: m.Vnode<ResendLinkViewAttrs, this>) {
    if (vnode.attrs.email != "") {
      //state.queryDataForToken(vnode.attrs.email);
      console.log(vnode.attrs.email);
      this.sent = true;
    }
  }
  view(_vnode: Vnode<ResendLinkViewAttrs, this>): Children {
    return m(".viewarea", {}, [
      m("form.max-w-md.space-y-3.mx-auto", [
        m("div.h-10.w-10"),
        this.sent ? m("div.welcome", {}, m("p",{},i18next.t("main.resent"))) : m(".max-w-md.space-y-3", [
          m(TextInput, {
            type: "email",
            label: i18next.t("form.email.label"),
            placeholder: i18next.t("form.email.placeholder"),
            value: this.email,
            required: true,
            id: "email",
            help: i18next.t("form.email.help"),
            oninput: (event: InputEvent) => {
              this.email = (event.target as HTMLInputElement).value;
            },
          }),
        ]),
        m("div.buttons", {}, [
          this.sent
            ? m("a.lostlink", {
              onclick: (_event: MouseEvent) => {
                m.route.set("/");
              },
            }, i18next.t("main.back"))
            : m(Button, {
              type: ButtonType.Dark,
              size: ButtonSize.SizeXl,
              onclick: (_event: MouseEvent) => {
                const url = `${globalThis.location.origin}/resendlink/${encodeURIComponent(this.email)}`
                globalThis.location.assign(url);
              },
            }, i18next.t("main.lostLink")),
        ]),
      ]),
    ]);
  }
}
