/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectAttrs {
  label: string;
  title?: string;
  help?: string;
  id?: string;
  options: SelectOption[];
  value?: string;
  required?: boolean;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

export class Select implements ClassComponent<SelectAttrs> {
  view(vnode: Vnode<SelectAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const selectOpts: m.Attributes = {
      class: "badge",
      style:
        'background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iZ2x5cGhpY29ucy1iYXNpYyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiIGNsYXNzPSJmaWxsLWN1cnJlbnQiPgogIDxwYXRoIGlkPSJzZXQiIGQ9Ik04LjMzNjMsMTIuMzY2NywxNS4yMjYsMy45NDU5MmExLjAwMDA4LDEuMDAwMDgsMCwwLDEsMS41NDgsMEwyMy42NjM3LDEyLjM2NjdBMSwxLDAsMCwxLDIyLjg4OTc3LDE0SDkuMTEwMjNBMSwxLDAsMCwxLDguMzM2MywxMi4zNjY3Wk0yMi44ODk3NywxOEg5LjExMDIzYTEsMSwwLDAsMC0uNzczOTMsMS42MzMzTDE1LjIyNiwyOC4wNTRhMS4wMDAwOCwxLjAwMDA4LDAsMCwwLDEuNTQ4LDBMMjMuNjYzNywxOS42MzMzQTEsMSwwLDAsMCwyMi44ODk3NywxOFoiLz4KPC9zdmc+Cg=="); background-repeat: no-repeat; background-size: 1rem 1rem; background-position: calc(100% - 0.5rem) center;',
    };
    if (vnode.attrs.title) {
      selectOpts.title = vnode.attrs.title;
    }
    if (vnode.attrs.required) {
      selectOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.disabled) {
      selectOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.oninput) {
      selectOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      selectOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
      if (vnode.attrs.help) {
        selectOpts["aria-describedby"] = helperOpts["id"];
      }
    }

    return m(".inputGroup", [
      m("label", lblOpts, vnode.attrs.label),
      m("select.select", selectOpts, [
        vnode.attrs.options.map((o) => {
          return m("option", {
            selected: o.value == vnode.attrs.value,
            value: o.value,
          }, o.label);
        }),
      ]),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}
/*
<label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
  <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option selected>Choose a country</option>
    <option value="US">United States</option>
    <option value="CA">Canada</option>
    <option value="FR">France</option>
    <option value="DE">Germany</option>
  </select>
*/
