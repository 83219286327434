/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

import { View } from "../components/View.ts";
import { Checkbox, TextInput, Toggle } from "../components/controls/Input.ts";
import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
import { Select } from "../components/controls/Select.ts";
import state, { FormData, Guest } from "../state.ts";
import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";

import Stream from "https://esm.sh/mithril@2.2.2/stream";

import plusIcon from "../assets/icons/plus.svg";

export interface FormViewAttrs {
  token: string;
  data: Stream<FormData>;
  //   listIndex: number;
  //   data: MeetingTopic;
  //   editing: boolean;
  //   onchange: (value: PlateEntry) => undefined;
  //   ondelete: (index: number) => undefined;
  //   onduplicate: (value: PlateEntry) => undefined;
  //   onaddafter: (index: number) => undefined;
}

export class FormView implements ClassComponent<FormViewAttrs> {
  oninit(vnode: m.Vnode<FormViewAttrs, this>) {
    if (vnode.attrs.token != "") {
      state.queryDataForToken(vnode.attrs.token);
    }
  }

  view(vnode: Vnode<FormViewAttrs, this>): Children {
    const formdata = vnode.attrs.data();
    return m(View, {}, [
      m("form.max-w-md.space-y-3.mx-auto", [
        m("div.h-10.w-10"),

        m(".max-w-md.space-y-3", [
          m(TextInput, {
            type: "text",
            label: i18next.t("form.name.label"),
            placeholder: i18next.t("form.name.placeholder"),
            value: formdata.employeeName,
            disabled: formdata.id != "",
            required: true,
            id: "name",
            help: i18next.t("form.name.help"),
            oninput: (event: InputEvent) => {
              formdata.employeeName = (event.target as HTMLInputElement).value;
              vnode.attrs.data(formdata);
            },
          }),
          m(TextInput, {
            type: "email",
            label: i18next.t("form.email.label"),
            placeholder: i18next.t("form.email.placeholder"),
            required: true,
            value: formdata.employeeEmail,
            disabled: formdata.id != "",
            id: "email",
            help: i18next.t("form.email.help"),
            oninput: (event: InputEvent) => {
              formdata.employeeEmail = (event.target as HTMLInputElement).value;
              vnode.attrs.data(formdata);
            },
          }),
          m(Select, {
            id: "attendig",
            label: i18next.t("form.attending.label"),
            help: i18next.t("form.attending.help"),
            value: formdata.attending ? "true" : "false",
            options: [
              { value: "false", label: i18next.t("form.attending.no") },
              { value: "true", label: i18next.t("form.attending.yes") },
            ],
            oninput: (event: InputEvent) => {
              if ((event.target as HTMLInputElement).value == "true") {
                formdata.attending = true;
              } else {
                formdata.attending = false;
              }
              vnode.attrs.data(formdata);
            },
          }),
        ]),
        m("div", {
          class: formdata.attending ? "max-w-md space-y-3" : "hidden",
        }, [
          m(TextInput, {
            type: "text",
            label: i18next.t("form.userid.label"),
            placeholder: i18next.t("form.userid.placeholder"),
            required: true,
            value: formdata.employeeUserId,
            id: "userid",
            help: i18next.t("form.userid.help"),
            oninput: (event: InputEvent) => {
              formdata.employeeUserId =
                (event.target as HTMLInputElement).value;
              vnode.attrs.data(formdata);
            },
          }),

          m("div.h-10.w-10"),
          // guest
          m(Toggle, {
            label: i18next.t("form.bringguest.label"),
            id: "bringguest",
            help: i18next.t("form.bringguest.help"),
            checked: formdata.guest === undefined || formdata.guest.delete
              ? false
              : true,
            oninput: (event: InputEvent) => {
              const checked = (event.target as HTMLInputElement).checked;
              if (checked && formdata.guest === undefined) {
                formdata.guest = {
                  name: "",
                  id: "",
                  owner: formdata.id,
                  delete: false,
                };
              } else if (!checked && formdata.guest !== undefined) {
                formdata.guest.delete = true;
              } else if (checked && formdata.guest !== undefined) {
                formdata.guest.delete = false;
              }
              console.log(formdata.guest);
              vnode.attrs.data(formdata);
            },
          }),
          m(
            "div",
            {
              class: formdata.guest === undefined || formdata.guest.delete
                ? "hidden"
                : "",
            },
            m(TextInput, {
              type: "text",
              label: i18next.t("form.guest.label"),
              placeholder: i18next.t("form.guest.placeholder"),
              value: formdata.guest == undefined ? "" : formdata.guest.name,
              id: "guest",
              help: i18next.t("form.guest.help"),
              oninput: (event: InputEvent) => {
                const n = (event.target as HTMLInputElement).value;
                if (formdata.guest) {
                  formdata.guest.name = n;
                }
                vnode.attrs.data(formdata);
              },
            }),
          ),

          // children
          m("div.h-10.w-10"),
          m(Toggle, {
            label: i18next.t("form.bringchild.label"),
            id: "bringchild",
            checked: (formdata.children.every((g) =>
                  g.delete
                )
                ? false
                : formdata.children.length > 0)
              ? true
              : false,
            help: i18next.t("form.bringchild.help"),
            oninput: (event: InputEvent) => {
              const checked = (event.target as HTMLInputElement).checked;
              if (checked && formdata.children.length == 0) {
                formdata.children.push({
                  name: "",
                  id: "",
                  owner: formdata.id,
                  delete: false,
                });
              } else if (
                checked && formdata.children.length > 0 &&
                formdata.children.every((g) => g.delete)
              ) {
                formdata.children = formdata.children.map((guest) => {
                  guest.delete = false;
                  return guest;
                });
              } else if (!checked && formdata.children.length > 0) {
                formdata.children = formdata.children.map((guest) => {
                  guest.delete = true;
                  return guest;
                });
              }
            },
          }),
          m(
            "div",
            {
              class: formdata.children.length === 0 ||
                  formdata.children.every((g) => g.delete)
                ? "hidden"
                : "text-center",
            },
            [
              formdata.children.map((g, idx) => {
                return m(TextInput, {
                  type: "text",
                  label: i18next.t("form.children.label") + ` ${idx + 1}`,
                  placeholder: i18next.t("form.children.placeholder"),
                  value: g.name,
                  id: `c${idx}`,
                  help: i18next.t("form.children.help"),
                  oninput: (event: InputEvent) => {
                    const elem = event.target as HTMLInputElement;
                    const idx = parseInt(elem.id.substring(1), 10);
                    console.log("id", idx);
                    formdata.children[idx].name = elem.value;
                    vnode.attrs.data(formdata);
                  },
                });
              }),
              m("div.h-4.w-10"),
              m(Button, {
                id: "addChild",
                size: ButtonSize.SizeXs,
                type: ButtonType.Alternative,
                onclick: (_event: MouseEvent) => {
                  formdata.children.push({
                    name: "",
                    id: "",
                    owner: formdata.id,
                    delete: false,
                  });
                  vnode.attrs.data(formdata);
                },
              }, [
                m.trust(
                  plusIcon,
                ),
                i18next.t("form.bringchild.add"),
              ]),
            ],
          ),
          m("div.h-10.w-10"),
          m(Checkbox, {
            label: i18next.t("form.responsible.label"),
            id: "responsible",
            help: i18next.t("form.responsible.help"),
            required: true,
            checked: formdata.responsible,
            oninput: (event: InputEvent) => {
              formdata.responsible = (event.target as HTMLInputElement).checked;
              vnode.attrs.data(formdata);
            },
          }),
          m("div.h-10.w-10"),
          m("hr"),
          // extra guests
          m(Toggle, {
            label: i18next.t("form.bringextraguest.label"),
            id: "bringextraguest",
            help: i18next.t("form.bringextraguest.help"),
            checked: (formdata.extraguests.every((g) =>
                  g.delete
                )
                ? false
                : formdata.extraguests.length > 0)
              ? true
              : false,
            oninput: (event: InputEvent) => {
              const checked = (event.target as HTMLInputElement).checked;
              if (checked && formdata.extraguests.length == 0) {
                formdata.extraguests.push({
                  name: "",
                  id: "",
                  owner: formdata.id,
                  delete: false,
                });
              } else if (
                checked && formdata.extraguests.length > 0 &&
                formdata.extraguests.every((g) => g.delete)
              ) {
                formdata.extraguests = formdata.extraguests.map((guest) => {
                  guest.delete = false;
                  return guest;
                });
              } else if (!checked && formdata.extraguests.length > 0) {
                formdata.extraguests = formdata.extraguests.map((guest) => {
                  guest.delete = true;
                  return guest;
                });
              }
              vnode.attrs.data(formdata);
              console.log(formdata);
            },
          }),
          m(
            "div",
            {
              class: formdata.extraguests.length === 0 ||
                  formdata.extraguests.every((g) => g.delete)
                ? "hidden"
                : "text-center",
            },
            [
              formdata.extraguests.map((g, idx) => {
                return m(TextInput, {
                  type: "text",
                  label: i18next.t("form.extraguest.label") + ` ${idx + 1}`,
                  placeholder: i18next.t("form.extraguest.placeholder"),
                  value: g.name,
                  id: `g${idx}`,
                  help: i18next.t("form.extraguest.help"),
                  oninput: (event: InputEvent) => {
                    const elem = event.target as HTMLInputElement;
                    const idx = parseInt(elem.id.substring(1), 10);
                    console.log("id", idx);
                    formdata.extraguests[idx].name = elem.value;
                    vnode.attrs.data(formdata);
                  },
                });
              }),
              m("div.h-4.w-10"),
              m(Button, {
                id: "addGuest",
                size: ButtonSize.SizeXs,
                type: ButtonType.Alternative,
                onclick: (_event: MouseEvent) => {
                  formdata.extraguests.push({
                    name: "",
                    id: "",
                    owner: formdata.id,
                    delete: false,
                  });
                  vnode.attrs.data(formdata);
                },
              }, [
                m.trust(
                  plusIcon,
                ),
                i18next.t("form.bringextraguest.add"),
              ]),
            ],
          ),
        ]),

        m("div.h-10.w-10"),
        m(".text-center", {}, [
          m(Button, {
            id: "sbmt",
            disabled: state.isBusy() == true,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
              console.log(formdata);
              if (formdata.responsible == false && formdata.attending) {
                alert(i18next.t("form.responsible.alert"));
              } else {
                if (
                  formdata.employeeName.trim().length == 0 ||
                  formdata.employeeEmail.trim().length == 0 ||
                  formdata.employeeEmail.indexOf("@") < 0
                ) {
                  alert(i18next.t("form.fill.alert"));
                } else {
                  alert("You can not change your registration anymore.")
                  m.route.set("/form/:id", {id: formdata.id});
                  globalThis.location.reload();
                  //state.commitChanges();
                }
              }
            },
          }, [
            i18next.t("form.submit.label"),
            m.trust(
              `<svg class="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16"><path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/><path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/></svg>`,
            ),
          ]),
          m("div.h-10.w-10"),
        ]),
      ]),
    ]);
  }
}
