/// <reference lib="dom" />
import m, {
    Children,
    ClassComponent,
    Vnode,
  } from "https://esm.sh/mithril@2.2.2";
    
  export interface ViewAttrs {
    id?:string
  }
  
 
  export class View implements ClassComponent<ViewAttrs> {
    view(vnode: Vnode<ViewAttrs, this>): Children {
      return m(".view",vnode.children)
    }
  }
  