/// <reference lib="dom" />
/// <reference path="../pb_data/types.d.ts" />

import m from "https://esm.sh/mithril@2.2.2";

import "./style.css";

import { App } from "./components/App.ts";
import { WelcomeView } from "./views/WelcomeView.ts";
import { FormView } from "./views/FormView.ts";

const rootElement: HTMLElement | null = document.getElementById("app");

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";

import state from "./state.ts";
import { KitchenSink } from "./components/KitchenSink.ts";
import { InfoView } from "./views/InfoView.ts";
import { ResendLinkView } from "./views/ResendLinkView.ts";

import german from './lang/de.js'
import english from './lang/en.js'
import { LoginView } from "./views/LoginView.ts";
import { TicketView } from "./views/TicketView.ts";
import { ScanView } from "./views/ScanView.ts";

i18next.init({
  lng: "de", // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    de: german,
    en: english
  },
});

if (rootElement) {
  m.route(rootElement, "/", {
    "/": {
      view: function () {
        return m(App, m(WelcomeView));
      },
    },
    "/form": {
      view: function () {
        return m(App, m(FormView, { token: "", data: state.formData }));
      },
    },
    "/form/:token": {
      view: function (vnode: { attrs: { token: string } }) {
        return m(
          App,
          m(FormView, { token: vnode.attrs.token, data: state.formData }),
        );
      },
    },
    "/info/:token": {
      view: function (vnode: { attrs: { token: string } }) {
        return m(App, m(InfoView, {token: vnode.attrs.token}));
      },
    },
    "/resendlink": {
      view: function () {
        return m(App, m(ResendLinkView, { email: "" }));
      },
    },
    "/resendlink/:email": {
      view: function (vnode: { attrs: { email: string } }) {
        return m(App, m(ResendLinkView, {email: vnode.attrs.email}));
      },
    },
    "/export": {
      view: function () {
        return m(App, m(LoginView));
      },
    },
    "/tickets": {
      view: function () {
        return m(App, m(TicketView, {token:""}));
      },
    },
    "/tickets/:token": {
      view: function (vnode: { attrs: { token: string } }) {
        return m(App, m(TicketView, {token: vnode.attrs.token}));
      },
    },
    "/sample": {
      view: function () {
        return m(App, KitchenSink);
      },
    },
    "/scan": {
      view: function () {
        return m(App, m(ScanView, {}));
      },
    },
  });

  //m.mount(rootElement, App);
}
