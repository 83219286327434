/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

export interface TextInputAttrs {
  type: string;
  label: string;
  help?: string;
  id?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  autocomplete?: string;
  oninput?: (event: InputEvent) => undefined;
}
/**
 * A text input field
 */
export class TextInput implements ClassComponent<TextInputAttrs> {
  view(vnode: Vnode<TextInputAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: vnode.attrs.type,
    };
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    } else {
      inputOpts["placeholder"] = "";
    }
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.autocomplete) {
      inputOpts["autocomplete"] = vnode.attrs.autocomplete;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".inputGroup", [
      m("label", lblOpts, vnode.attrs.label),
      m("input.peer", inputOpts),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

export interface TextInputFloatingAttrs {
  type: string;
  label: string;
  help?: string;
  id?: string;
  value?: string;
  small?: boolean;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

/**
 * A text input field
 */
export class TextInputFloating implements ClassComponent<TextInputFloatingAttrs> {
  view(vnode: Vnode<TextInputFloatingAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: vnode.attrs.type,
    };
    inputOpts["placeholder"] = " ";
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.small) {
      inputOpts["class"] = "small";
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".floatingInputGroup", [
      m(".group", [
        m("input.peer", inputOpts),
        m("label", lblOpts, vnode.attrs.label),    
      ]),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

/**
 * A date input field
 */
export class DateInputFloating implements ClassComponent<DateInputAttrs> {
  view(vnode: Vnode<DateInputAttrs, this>): Children {
    const grpOpts: m.Attributes = {};
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "date",
    };
    if (vnode.attrs.narrow) {
      grpOpts.class = "max-w-date";
    }
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    }
    if (vnode.attrs.min) {
      inputOpts["min"] = vnode.attrs.min;
    }
    if (vnode.attrs.max) {
      inputOpts["max"] = vnode.attrs.max;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".floatingInputGroup", grpOpts, [
      m(".group", [
        m("input.peer", inputOpts),
        m("label", lblOpts, vnode.attrs.label),    
      ]),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

export interface TimeInputAttrs {
  label: string;
  help?: string;
  id?: string;
  value?: string;
  min?: string;
  max?: string;
  required?: boolean;
  placeholder?: string;
  narrow?: boolean;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

/**
 * A time input field
 */
export class TimeInput implements ClassComponent<TimeInputAttrs> {
  view(vnode: Vnode<TimeInputAttrs, this>): Children {
    const grpOpts: m.Attributes = {};
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "time",
    };
    if (vnode.attrs.narrow) {
      grpOpts.class = "inputGroup max-w-time";
    }
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    }
    if (vnode.attrs.min) {
      inputOpts["min"] = vnode.attrs.min;
    }
    if (vnode.attrs.max) {
      inputOpts["max"] = vnode.attrs.max;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".inputGroup", grpOpts, [
      m("label", lblOpts, vnode.attrs.label),
      m("input.peer", inputOpts),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

/**
 * A time input field
 */
export class TimeInputFloating implements ClassComponent<TimeInputAttrs> {
  view(vnode: Vnode<TimeInputAttrs, this>): Children {
    const grpOpts: m.Attributes = {};
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "time",
    };
    if (vnode.attrs.narrow) {
      grpOpts.class = "max-w-time";
    }
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    }
    if (vnode.attrs.min) {
      inputOpts["min"] = vnode.attrs.min;
    }
    if (vnode.attrs.max) {
      inputOpts["max"] = vnode.attrs.max;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".floatingInputGroup", grpOpts, [
      m(".group", [
        m("input.peer", inputOpts),
        m("label", lblOpts, vnode.attrs.label),    
      ]),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

export interface DateInputAttrs {
  label: string;
  help?: string;
  id?: string;
  value?: string;
  min?: string;
  max?: string;
  required?: boolean;
  placeholder?: string;
  narrow?: boolean;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

/**
 * A date input field
 */
export class DateInput implements ClassComponent<DateInputAttrs> {
  view(vnode: Vnode<DateInputAttrs, this>): Children {
    const grpOpts: m.Attributes = {};
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "date",
    };
    if (vnode.attrs.narrow) {
      grpOpts.class = "inputGroup max-w-date";
    }
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    }
    if (vnode.attrs.min) {
      inputOpts["min"] = vnode.attrs.min;
    }
    if (vnode.attrs.max) {
      inputOpts["max"] = vnode.attrs.max;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".inputGroup", grpOpts, [
      m("label", lblOpts, vnode.attrs.label),
      m("input.peer", inputOpts),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

export interface TextAreaInputAttrs {
  label: string;
  help?: string;
  id?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

/**
 * A text area
 */
export class TextAreaInput implements ClassComponent<TextAreaInputAttrs> {
  view(vnode: Vnode<TextAreaInputAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      rows: "4",
    };
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.placeholder) {
      inputOpts["placeholder"] = vnode.attrs.placeholder;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".inputGroup", [
      m("label", lblOpts, vnode.attrs.label),
      m("textarea.peer", inputOpts),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

/*
<div class="flex items-center mb-4">
    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Default checkbox</label>
</div>
*/

export interface CheckboxAttrs {
  label: string;
  help?: string;
  id?: string;
  value?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  oninput?: (event: InputEvent) => undefined;
}

/**
 * A checkbox
 */
export class Checkbox implements ClassComponent<CheckboxAttrs> {
  view(vnode: Vnode<CheckboxAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "checkbox",
    };
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.checked) {
      inputOpts["checked"] = vnode.attrs.checked;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
      if (vnode.attrs.help) {
        inputOpts["aria-describedby"] = helperOpts["id"];
      }
    }

    return m(".inputGroup", [
      m("input.peer", inputOpts),
      m("label", lblOpts, vnode.attrs.label),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}

/**
 * A toggle
 */
export class Toggle implements ClassComponent<CheckboxAttrs> {
  view(vnode: Vnode<CheckboxAttrs, this>): Children {
    const lblOpts: m.Attributes = {};
    const helperOpts: m.Attributes = {};
    const inputOpts: m.Attributes = {
      type: "checkbox",
    };
    if (vnode.attrs.disabled) {
      inputOpts["disabled"] = vnode.attrs.disabled;
    }
    if (vnode.attrs.required) {
      inputOpts["required"] = vnode.attrs.required;
    }
    if (vnode.attrs.checked) {
      inputOpts["checked"] = vnode.attrs.checked;
    }
    if (vnode.attrs.value) {
      inputOpts["value"] = vnode.attrs.value;
    }
    if (vnode.attrs.oninput) {
      inputOpts["oninput"] = vnode.attrs.oninput;
    }
    if (vnode.attrs.id) {
      inputOpts["id"] = vnode.attrs.id;
      lblOpts["for"] = vnode.attrs.id;
      helperOpts["id"] = `${vnode.attrs.id}-helper-text`;
    }
    if (vnode.attrs.help) {
      inputOpts["aria-describedby"] = vnode.attrs.id;
    }
    return m(".inputGroup", [
      m("label.toggle", lblOpts, [
        m("input.peer.sr-only", inputOpts),
        m(".toggle"),
        vnode.attrs.label ? m("span.ms-3", {}, vnode.attrs.label) : undefined,
      ]),
      vnode.attrs.help
        ? m("p.helper", helperOpts, vnode.attrs.help)
        : undefined,
    ]);
  }
}
