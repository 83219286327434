/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

/*
<div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
    </a>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Read more
        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
    </a>
</div>
*/

export interface CardAttrs {
  title?: string;
  image?: string;
  text?: string;
  id?: string;
  class?: string;
}

/**
 * A Card
 */

export class Card implements ClassComponent<CardAttrs> {
  view(vnode: Vnode<CardAttrs, this>): Children {
    const children: Children = [];
    let img: m.Child = undefined;
    const cardOpts: m.Attributes = {
      id: "",
      class: "",
    };
    if (vnode.attrs.image) {
      img = m("img", { class: "rounded-t-lg", src: vnode.attrs.image });
    }

    if (vnode.attrs.title) {
      children.push(m("h5", {}, vnode.attrs.title));
    }
    if (vnode.attrs.class) {
      cardOpts.class += " " + vnode.attrs.class;
    }
    if (vnode.attrs.id) {
      cardOpts["id"] = vnode.attrs.id;
    }
    return m("div.card", cardOpts, [
      img,
      m("div.p-5", [...children, vnode.children]),
    ]);
  }
}

/*
<div class="flex flex-col">
                    <dt class="mb-2 text-3xl font-extrabold">73M+</dt>
                    <dd class="text-gray-500 dark:text-gray-400">Developers</dd>
                </div>
*/

export interface FactAttrs {
  number?: string;
  label?: string;
  id?: string;
  class?: string;
}

/**
 * A fact
 */
export class Fact implements ClassComponent<FactAttrs> {
  view(vnode: Vnode<FactAttrs, this>): Children {
    let children: Children = undefined;
    const factOpts: m.Attributes = {
      id: "",
      class: "",
    };
    if (vnode.attrs.number && vnode.attrs.label) {
      children = [
        m("dt", {}, vnode.attrs.number),
        m("dd", {}, vnode.attrs.label),
      ];
    }
    if (vnode.attrs.class) {
      factOpts.class += " " + vnode.attrs.class;
    }
    if (vnode.attrs.id) {
      factOpts["id"] = vnode.attrs.id;
    }
    return m("div.fact", factOpts, children);
  }
}

/*<a href="#" class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
<p class="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
</a>*/
