const de = {
  translation: {
    "appname": "ticktes",
    "theme": {
      "dark": "Dark Theme",
      "light": "Light Theme",
      "auto": "Theme based on system theme",
    },
    "main": {
      "continue": "Zur Anmeldung",
      "lostLink": "Bearbeitungslink erneut zusenden",
      "resent": "Dein Bearbeitungslink wurde an deine E-Mail Adresse geschickt.",
      "back": "Zurück",
      "login": "Login",
    },
    "tickets.heading": "Ticktes",
    "tickets.employee": "Mitarbeiter",
    "tickets.guest": "Gast",
    "tickets.child": "Kind",
    "tickets.extraguest": "Extra Gast",
    "tickets.download": "Herunterladen",
    "tickets.downloadAll": "Lade alle Tickets herunter",
    "tickets.info": `
### Tickets 

Du hast für folgende Personen Tickets erhalten. Bitte bringe die Tickets auf deinem Handy oder ausgedruckt mit.
    `,
    "error.email.unique":
      "Es gibt bereits eine Anmeldung für diese E-Mail Adressse.",
    "info.attending.yes": "angemeldet",
    "info.attending.no": "**nicht** angemeldet",
    "info.nobody": "*niemanden*",
    "info.children": "die Kinder",
    "info.nochildren": "*keine Kinder*",
    "info.extras": "ggf. folgende zusätzliche Gäste mitbringen:",
    "info.noextras": "*keine zusätzlichen Gäste* mitbringen.",
    "info.template": `
### Zusammenfassung deiner Anmeldung Familientag @ Design

Hallo {m}

du hast dich für den Familientag @ Design {t}.

Du bringst {g} als Gast mit. Du bringst {k} mit.
Falls wir noch Tickets übrig haben, möchtest du {xg}

Bearbeiten kannst du deine Rückmeldung **bis zum 16. September** unter folgendem Link (gerne abspeichern):

[{l}]({a})

Wir freuen uns auf euch!
      `,
    "welcome": `
### Anmeldung zum Familientag im Design!

**Liebe Kolleginnen und Kollegen,**

wir freuen uns, euch und eure Familien herzlich zu unserem Familientag im Designbereich einzuladen! Der Familientag findet **am 21.09.
von 12:00 bis 16:00 Uhr im Gebäude 80** statt. Anmelden könnt ihr euch über das unten verlinkte Formular.

Aufgrund behördlicher Auflagen ist die Teilnehmeranzahl auf 1000 Personen beschränkt. **Daher gelten für die Anmeldung folgende Regeln**:

- Teilnahmeberechtigt sind alle Mitarbeitenden des Designbereichs.
- Jeder Mitarbeitende darf einen Gast und Kinder mitbringen.
- Wer gerne mehr Gäste mitbringen möchte, kann diese im Anmeldeformular hinterlegen, um ggf. weitere Tickets zu erhalten.
- Sollten nach der ersten Anmelderunde noch Tickets verfügbar sein, werden diese unter denjenigen verlost, die gerne mehr Gäste mitbringen möchten.

Wir freuen uns auf einen wunderbaren Tag voller Spaß und gemeinsamer Erlebnisse!

Euer Familientag-Team
`,
    "form.password.label": "Passwort",
    "form.password.help": "Gib hier dein Passwort ein",
    "form.name.label": "Name Design Mitarbeiter",
    "form.name.placeholder": "z.B. Max Mustermann",
    "form.name.help": "Gib hier deinen Namen ein",
    "form.email.label": "E-Mail Adresse",
    "form.email.placeholder": "z.B. max.mustermann@mercedes-benz.com",
    "form.email.help": "Gib hier deine E-Mail Adresse ein",
    "form.userid.label": "User Id",
    "form.userid.placeholder": "z.B. MMUSTER",
    "form.userid.help": "Gib hier deine User Id ein",
    "form.attending.label": "Teilnahme",
    "form.attending.yes": "Ich nehme am Familientag teil",
    "form.attending.no": "Ich kann nicht teilnehmen",
    "form.attending.help": "Wähle aus, ob du teilnehmen kannst.",
    "form.bringguest.label": "Ich bringe einen Gast mit",
    "form.bringguest.help":
      "Aktiviere diesen Schalter wenn du einen Gast mitbringen willst.",
    "form.guest.label": "Name Gast",
    "form.guest.placeholder": "z.B. Martina Mustermann",
    "form.guest.help": "Gib hier den Namen deines Gastes ein.",
    "form.extraguest.label": "Name weiterer Gast",
    "form.extraguest.placeholder": "z.B. Martin Mustermann",
    "form.extraguest.help":
      "Gib hier den Namen deines potentiellen weiteren Gastes ein.",
    "form.bringextraguest.label":
      "Ich würde, wenn möglich, gerne weitere Gäste mitbringen",
    "form.bringextraguest.help":
      "Aktiviere diesen Schalter wenn du gerne weiter Gäste mitbringen würdest. Falls wir die Obergrenze an Besuchern nicht erreichen werden, werden wir die übrigen Karten unter den hier angegebenen Personen verlosen.",
    "form.bringextraguest.add": "weiteren Gast hinzufügen",
    "form.bringchild.label": "Ich bringe Kinder mit",
    "form.bringchild.help":
      "Aktiviere diesen Schalter wenn du Kinder mitbringen willst. Falls du ein Kind entfernen möchtest, schicke das Formular einfach mit einem leeren Namen ab.",
    "form.bringchild.add": "weiteres Kind hinzufügen",
    "form.children.label": "Name Kind",
    "form.children.placeholder": "z.B. Madlene Mustermann",
    "form.children.help": "Gib hier den Namen deines Kindes ein.",
    "form.submit.label": "Abschicken",
    "form.responsible.label":
      "Ich übernehme die Verantwortung und Aufsicht für meine Gäste",
    "form.responsible.help":
      "Als Gastgeber tragt ihr die Verantwortung, dass sich eure Gäste an die Regeln auf unserem Gelände halten sowie nicht ohne Aufsicht auf dem Gelände unterwegs sind.",
    "form.fill.alert": "Bitte Formular vollständig ausfüllen.",
    "form.responsible.alert":
      "Bitte bestätige, dass du die Verantwortung und Aufsicht für deine Gäste übernimmst.",
  },
};

export default de;
