/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";


import { Card } from "../components/controls/Card.ts";
import { Grid } from "../components/layout/Grid.ts";
import state, { FormData } from "../state.ts";
import { Badge, BadgeColor } from "../components/controls/Badge.ts";
import { Renderer } from "jsr:@libs/markdown@^1.2.2";
import { BadgeType } from "../components/controls/Badge.ts";
import { TicketPDFGenerator } from "../pdfticketgen.ts";

export interface PDFAllTicketsAttrs {
  owner: string;
  data: Array<PDFTicketAttrs>;
}

export class PDFAllTickets implements ClassComponent<PDFAllTicketsAttrs> {
  src!: string;
  async onupdate(vnode: m.VnodeDOM<PDFAllTicketsAttrs, this>) {
    
  }
  async oninit(vnode: m.Vnode<PDFAllTicketsAttrs, this>) {
    this.src = "";
    try {
      const ticketGen = new TicketPDFGenerator()
      await ticketGen.init()
      for(const t of vnode.attrs.data){
        ticketGen.makePage("Familientag Mercedes-Benz Design",t.name, t.code, t.type)
      }
      this.src = await ticketGen.toDataURI()
      m.redraw();  
    } catch (error) {
      console.error(error) 
    }
  }
  view(vnode: Vnode<PDFAllTicketsAttrs, this>): Children {
    return [m("div.mb-4"),m("a.btn.btn-default.w-full.justify-center", {
      href: this.src,
      download: `Alle_Tickets_Familientag_${
        vnode.attrs.owner.replaceAll(" ", "-")
      }.pdf`,
      target: "_blank",
    }, i18next.t("tickets.downloadAll"))];
  }
}

export interface PDFTicketAttrs {
  code: string;
  name: string;
  type: string;
}

export class PDFTicket implements ClassComponent<PDFTicketAttrs> {
  src!: string;
  async oninit(vnode: m.Vnode<PDFTicketAttrs, this>) {
    this.src = "";
    try {
      const ticketGen = new TicketPDFGenerator()
      await ticketGen.init()
      ticketGen.makePage("Familientag Mercedes-Benz Design",vnode.attrs.name, `${vnode.attrs.code}:${vnode.attrs.type}`, vnode.attrs.type)
      this.src = await ticketGen.toDataURI()
      m.redraw();  
    } catch (error) {
      console.error(error) 
    }
  }
  view(vnode: Vnode<PDFTicketAttrs, this>): Children {
    return [m("div.mb-4"),m("a.btn.btn-default.w-full.justify-center", {
      href: this.src,
      download: `Ticket_Familientag_${
        vnode.attrs.name.replaceAll(" ", "-")
      }.pdf`,
      target: "_blank",
    }, i18next.t("tickets.download"))];
  }
}

export interface TicketViewAttrs {
  id?: string;
  token: string;
}

export class TicketView implements ClassComponent<TicketViewAttrs> {
  markdown!: string;
  fd!: FormData;
  tinfo!: Array<PDFTicketAttrs>;
  oninit(vnode: m.Vnode<TicketViewAttrs, this>) {
    if (vnode.attrs.token != "") {
      state.queryDataForToken(vnode.attrs.token);
    }
  }
  onupdate(_vnode: m.VnodeDOM<TicketViewAttrs, this>) {
    Renderer.render(i18next.t("tickets.info")).then((value: string) => {
      this.markdown = value;
      m.redraw();
    }).catch((reason: string) => {
      this.markdown = reason;
    });

    this.fd = state.formData();
    this.tinfo = []
    this.tinfo.push({name: this.fd.employeeName, code: `${this.fd.id}:e`, type: 'e'})
    if(this.fd.guest){
      this.tinfo.push({name: this.fd.guest.name, code: `${this.fd.guest.id}:g`, type: 'g'})
    }
    if(this.fd.children.length > 0){
      for(const c of this.fd.children)
      this.tinfo.push({name: c.name, code: `${c.id}:k`, type: 'k'})
    }
    if(this.fd.extraguests.length > 0){
      for(const xg of this.fd.extraguests)
      this.tinfo.push({name: xg.name, code: `${xg.id}:xg`, type: 'xg'})
    }
  }
  view(_vnode: Vnode<TicketViewAttrs, this>): Children {    
    return m(".view", [
      m("div.md", {}, m.trust(this.markdown)),
      m(Grid, {
        cols: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
        gap: "gap-4",
      }, [
        this.fd && this.fd.attending == true
          ? [
            m(Card, {
              title: this.fd.employeeName,
            }, [
              m(
                Badge,
                { color: BadgeColor.Yellow,
                  type: BadgeType.Bordered
                 },
                i18next.t("tickets.employee"),
              ),
              m(PDFTicket, { code: this.fd.id, name: this.fd.employeeName, type: "e" }),
            ]),
            this.fd.guest != undefined
              ? m(Card, {
                title: this.fd.guest.name,
              }, [
                m(
                  Badge,
                  { color: BadgeColor.Default },
                  i18next.t("tickets.guest"),
                ),
                m(PDFTicket, {
                  code: this.fd.guest.id,
                  name: this.fd.guest.name,
                  type: "g",
                }),
              ])
              : undefined,
            ...this.fd.children.map((c) => {
              return m(Card, {
                title: c.name,
              }, [
                m(
                  Badge,
                  { color: BadgeColor.Purple },
                  i18next.t("tickets.child"),
                ),
                m(PDFTicket, { code: c.id, name: c.name, type: "k" }),
              ]);
            }),
            ...this.fd.extraguests.map((c) => {
              return m(Card, {
                title: c.name,
              }, [
                m(
                  Badge,
                  { color: BadgeColor.Default },
                  i18next.t("tickets.extraguest"),
                ),
                m(PDFTicket, { code: c.id, name: c.name, type: "xg" }),
              ]);
            }),
          ]
          : undefined,
      ]),
      this.fd ? m("div.md.my-10", {}, m(PDFAllTickets, {data: this.tinfo, owner: this.fd.employeeName})) : undefined
    ]);
  }
}
