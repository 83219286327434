/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

import { Toolbar } from "./controls/Toolbar.ts";

import LogoIcon from "../assets/icons/logo.svg";


export class App implements ClassComponent<never> {
  constructor(_vnode: Vnode) {
    
  }

  public view(vnode: Vnode<never, this>): Children {
    return m("main.flex.flex-col.h-full.w-full", [
      m(Toolbar, {}, [
        m("button", {
          class: "w-40",
          onclick: (_event: MouseEvent) => {
            m.route.set("/");
          },
        }, m.trust(LogoIcon)),
        m(".grow"),
        m(".w-10"),
      ]),
      vnode.children
    ]);
  }
}
