/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";
import { Button, ButtonSize, ButtonType } from "../components/controls/Button.ts";
// Render markdown using the default renderer
import { Renderer } from "jsr:@libs/markdown@^1.2.2";

import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";

export interface WelcomeViewAttrs {
  id?: string;
}

export class WelcomeView implements ClassComponent<WelcomeViewAttrs> {
  markdown: string = "";
  oninit(_vnode: m.Vnode<WelcomeViewAttrs, this>) {
  }
  view(_vnode: Vnode<WelcomeViewAttrs, this>): Children {
    Renderer.render(i18next.t("welcome")).then((value: string) => {
      this.markdown = value;
      m.redraw();
    }).catch((reason: string) => {
      this.markdown = reason;
    });
    return m(".viewarea", {}, [
      m("div.welcome", {}, m.trust(this.markdown)),
      m("div.buttons", {}, [
        m(Button, {
          type: ButtonType.Dark,
          size: ButtonSize.SizeXl,
          onclick: (_event: MouseEvent) => {
            //m.route.set("form");
            alert("Registration is closed.")
          },
        }, i18next.t("main.continue")),
      ]),
      m("div.buttons", {}, [
        m("a.lostlink", {
          onclick: (event: MouseEvent) => {
            m.route.set("resendlink");
            event?.preventDefault()
          },
        }, i18next.t("main.lostLink")),
      ]),
    ]);
  }
}
