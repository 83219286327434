import { init } from "https://deno.land/x/i18next@v23.11.5/index.js";
import {
    layoutMultilineText,
    PDFDocument,
    PDFFont,
    rgb,
    StandardFonts,
    TextAlignment,
} from "npm:pdf-lib";
import { qrcode } from "jsr:@libs/qrcode";

export class TicketPDFGenerator {
    doc: PDFDocument | undefined;
    fontTimes!: PDFFont;
    fontHelvetica!: PDFFont;
    constructor() {
    }

    async init() {
        this.doc = await PDFDocument.create();
        this.fontHelvetica = await this.doc.embedFont(StandardFonts.Helvetica);
        this.fontTimes = await this.doc.embedFont(StandardFonts.TimesRoman);
    }

    makePage(title: string, name: string, code: string, type: string) {
        if (this.doc) {
            const page = this.doc.addPage([375, 590]);

            const { width, height } = page.getSize();
            const centerY = height / 2;

            if (type == "e") {
                page.drawRectangle({
                    x: 0,
                    y: height,
                    width: width,
                    height: -height,
                    borderWidth: 30,
                    borderColor: rgb(1.0, 1.0, 0.0),
                    color: rgb(1, 1, 1),
                });
            }

            const multiText = layoutMultilineText(title, {
                alignment: TextAlignment.Center,
                font: this.fontTimes,
                fontSize: 32,
                bounds: {
                    x: 25,
                    y: 25,
                    width: width - 50,
                    height: height - 50,
                },
            });
            page.setFont(this.fontTimes);
            for (let i = 0; i < multiText.lines.length; i++) {
                page.drawText(`${multiText.lines[i].text}`, {
                    x: multiText.lines[i].x,
                    y: multiText.lines[i].y,
                    size: multiText.fontSize,
                    lineHeight: 44,
                });
            }

            const multiText2 = layoutMultilineText(`${name}`, {
                alignment: TextAlignment.Center,
                font: this.fontHelvetica,
                fontSize: 32,
                bounds: {
                    x: 25,
                    y: -height + (50 + 44 + 44 + 44),
                    width: width - 50,
                    height: height - 50,
                },
            });
            page.setFont(this.fontHelvetica);
            for (let i = 0; i < multiText2.lines.length; i++) {
                page.drawText(`${multiText2.lines[i].text}`, {
                    x: multiText2.lines[i].x,
                    y: multiText2.lines[i].y,
                    size: multiText2.fontSize,
                    lineHeight: 44,
                });
            }

            const svg = qrcode(code, {
                output: "svg",
                ecl: "MEDIUM",
            });

            page.drawSvgPath(svg, {
                x: 62.5 - 40,
                y: centerY + 180,
                color: rgb(0, 0, 0),
                scale: 10,
            });
        }
    }

    async toDataURI(): Promise<string> {
        if (this.doc) return await this.doc.saveAsBase64({ dataUri: true });
        return ""
    }
}
