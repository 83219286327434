/// <reference lib="dom" />
import m, {
    Children,
    ClassComponent,
    Vnode,
  } from "https://esm.sh/mithril@2.2.2";
  import { View } from "./View.ts";

  import {
    ProgressBar,
    ProgressColor,
    ProgressSize,
    ProgressType,
  } from "./controls/Progress.ts";
  import {
    Checkbox,
    DateInput,
    DateInputFloating,
    TextAreaInput,
    TextInput,
    TextInputFloating,
    TimeInput,
    TimeInputFloating,
    Toggle,
  } from "./controls/Input.ts";
  import { Button, ButtonSize, ButtonType } from "./controls/Button.ts";
  import { Badge, BadgeColor, BadgeSize, BadgeType } from "./controls/Badge.ts";
  import { Select } from "./controls/Select.ts";

  export const KitchenSink = m(View,{},
    [
      m(".max-w-sm.space-y-3", [
        m("div.h-10.w-10"),
        
        
        m("div.h-10.w-10"),
        m(TextInputFloating, {
          type: "text",
          label: "Text",
          id: "my-text",
          help: "A simple text input",
          oninput: (event: InputEvent) => {
            console.log(
              "--",
              (event.target as HTMLInputElement).value,
            );
          },
        }),
        m(".flex.gap-2.", {}, [

          m(TextInputFloating, {
            type: "text",
            label: "TextSmall",
            small: false,
            id: "my-text2",
            help: "A simple text input",
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(DateInputFloating, {
            label: "Date",
            id: "my-date",
            help: "A simple date input",
            min: "2024-01-01",
            max: "2024-12-31",
            narrow: true,
            required: true,
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(TimeInputFloating, {
            label: "Start",
            id: "my-time3",
            help: "A simple time input",
            value: "",
            narrow: true,
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m("div.h-10.w-10"),
        ]),
        ]),
        
        m(ProgressBar, {
          title: "Default",
          progress: 0.5,
          type: ProgressType.Default,
          color: ProgressColor.Yellow,
          size: ProgressSize.SizeSm,
        }),
        m(ProgressBar, {
          title: "BarProgress",
          progress: 0.5,
          color: ProgressColor.Indigo,
          type: ProgressType.BarProgress,
        }),
        m(ProgressBar, {
          title: "BarLabel",
          progress: 0.5,
          type: ProgressType.BarLabel,
          color: ProgressColor.Purple,
          size: ProgressSize.SizeXl,
        }),
        m(ProgressBar, {
          title: "BarLabelProgress",
          progress: 0.5,
          color: ProgressColor.Pink,
          type: ProgressType.BarLabelProgress,
        }),
        m(".max-w-sm.space-y-3", [
          m(TextInput, {
            type: "text",
            label: "Text",
            placeholder: "Text",
            value: "Hallo ... ",
            id: "my-text",
            help: "A simple text input",
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(TimeInput, {
            label: "Start",
            id: "my-time",
            help: "A simple time input",
            min: "08:30",
            max: "12:30",
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(DateInput, {
            label: "Date",
            id: "my-date",
            help: "A simple date input",
            min: "2024-01-01",
            max: "2024-12-31",
            required: true,
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(TextAreaInput, {
            label: "Textarea",
            id: "my-textarea",
            help: "A simple text area input",
            placeholder: "your message here...",
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).value,
              );
            },
          }),
          m(Checkbox, {
            label: "Checkbox",
            id: "my-checkbox",
            help: "A simple checkbox",
            required: true,
            checked: false,
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).checked,
              );
            },
          }),
          m(Toggle, {
            label: "Toggle",
            id: "my-toggle",
            help: "A simple toggle",
            required: true,
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLInputElement).checked,
              );
            },
          }),
          m(Select, {
            label: "Select",
            options: [
              { value: "de", label: "Deutsch" },
              { value: "en", label: "Englisch" },
              { value: "es", label: "Spanisch" },
            ],
            oninput: (event: InputEvent) => {
              console.log(
                "--",
                (event.target as HTMLSelectElement).value,
              );
            },
            help: "select a language",
          }),
          m(Badge, {}, "my Badge"),
          m(Badge, {
            type: BadgeType.Bordered,
            color: BadgeColor.Green,
            size: BadgeSize.SizeLg,
          }, "my Badge"),
          m(Badge, {
            type: BadgeType.Pill,
            color: BadgeColor.Yellow,
            size: BadgeSize.SizeLg,
          }, "Yellow"),
          m(Button, {
            id: "my-btn",
            size: ButtonSize.SizeXs,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, [
            "Button",
            m.trust(
              `<svg class="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16"><path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/><path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/></svg>`,
            ),
          ]),
          m(Button, {
            type: ButtonType.Alternative,
            size: ButtonSize.SizeSm,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.Dark,
            size: ButtonSize.SizeM,
            disabled: true,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.Light,
            size: ButtonSize.SizeLg,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, [
            m.trust(
              `<svg class="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16"><path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/><path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/></svg>`,
            ),
            "Button",
          ]),
          m(Button, {
            type: ButtonType.Green,
            size: ButtonSize.SizeXl,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            title: "A yellow Button",
            type: ButtonType.Yellow,
            onclick: (event: MouseEvent) => {
              Connect();
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.Red,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlineDefault,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlineDark,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlineGreen,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlineYellow,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlineRed,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
          m(Button, {
            type: ButtonType.OutlinePurple,
            onclick: (event: MouseEvent) => {
              console.log(
                "-click-",
                event.target as HTMLInputElement,
              );
            },
          }, "Button"),
        ]),
      ],
  );
  