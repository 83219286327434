/// <reference lib="dom" />
import m, {
    Children,
    ClassComponent,
    Vnode,
  } from "https://esm.sh/mithril@2.2.2";
  
  
  export interface GridAttrs {
    cols?: string;
    gap?: string;
    id?: string;
    class?: string;
  }
  
  /**
   * A Grid
   */
  
  export class Grid implements ClassComponent<GridAttrs> {
  
    view(vnode: Vnode<GridAttrs, this>): Children {
      const gridOpts: m.Attributes = {
        id: "",
        class: "",
      };
      if (vnode.attrs.cols) {
        gridOpts.class += " " + vnode.attrs.cols;
      }
      if (vnode.attrs.gap) {
        gridOpts.class += " " + vnode.attrs.gap;
      }
      if (vnode.attrs.class) {
        gridOpts.class += " " + vnode.attrs.class;
      }
      if (vnode.attrs.id) {
        gridOpts["id"] = vnode.attrs.id;
      }
      return m("div.grid", gridOpts, vnode.children);
    }
  }