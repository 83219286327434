/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

export enum BadgeType {
  Default = "badge-default",
  Bordered = "badge-bordered",
  Pill = "badge-pill",
}

export enum BadgeSize {
  SizeMd = "size-md",
  SizeLg = "size-lg",
}

export enum BadgeColor {
  Default = "color-default",
  Dark = "color-dark",
  Red = "color-red",
  Green = "color-green",
  Yellow = "color-yellow",
  Indigo = "color-indigo",
  Purple = "color-purple",
  Pink = "color-pink",
}

export interface BadgeAttrs {
  type?: BadgeType;
  title?: string;
  size?: BadgeSize;
  color?: BadgeColor;
  disabled?: boolean;
  onclick?: (event: MouseEvent) => undefined;
}
/**
 * A Badge
 */
export class Badge implements ClassComponent<BadgeAttrs> {
  view(vnode: Vnode<BadgeAttrs, this>): Children {
    const badgeOpts: m.Attributes = {
      class: "badge",
    };
    if (vnode.attrs.title) {
      badgeOpts.title = vnode.attrs.title;
    }
    if (vnode.attrs.type) {
      badgeOpts.class += " " + vnode.attrs.type;
    } else {
      badgeOpts.class += " " + BadgeType.Default;
    }
    if (vnode.attrs.color) {
      badgeOpts.class += " " + vnode.attrs.color;
    } else {
      badgeOpts.class += " " + BadgeColor.Default;
    }
    if (vnode.attrs.size) {
      badgeOpts.class += " " + vnode.attrs.size;
    }

    if (vnode.attrs.disabled) {
      badgeOpts["disabled"] = vnode.attrs.disabled;
    }

    if (vnode.attrs.onclick) {
      badgeOpts.class += " cursor-pointer";
      badgeOpts["onclick"] = vnode.attrs.onclick;
    }else{
      badgeOpts.class += " cursor-default";
    }
    return m("span", badgeOpts, vnode.children);
  }
}
