const en = {
  translation: {
    "appname": "ticktes",
    "toolbar": {
      "save": "Save",
      "load": "Load",
    },
    "theme": {
      "dark": "Dark Theme",
      "light": "Light Theme",
      "auto": "Theme based on system theme",
    },
    "main": {
      "continue": "Continue to registration",
      "lostLink": "resend registration link",
      "resent": "Your registration link has been sent to your email address.",
      "back": "Back",
      "login": "Login",
    },
    "tickets.heading": "Ticktes",
    "tickets.employee": "Employee",
    "tickets.guest": "Guest",
    "tickets.child": "Child",
    "tickets.extraguest": "Extra Guest",
    "tickets.download": "Download",
    "tickets.downloadAll": "Download all tickets",
    "tickets.info": `
### Tickets 

You have recieved tickets for the following persons. Please bring them with you on your phone, or print them out.
    `,
    "error.email.unique": "This email address has already been registred.",
    "info.attending.yes": "registered",
    "info.attending.no": "**not** registered",
    "info.nobody": "*nobody*",
    "info.children": "the children",
    "info.nochildren": "*no children*",
    "info.extras": "to bring the following additional guests:",
    "info.noextras": "to bring *no additional guests*.",
    "info.template": `
### Summary of your registration for Family Day @ Design
    
Hello {m}

you have {t} for Family Day @ Design.

You are bringing {g} as a guest. You are bringing {k}.
If we still have tickets left, you would like {xg}

You can edit your response **until September 16th** at the following link (please bookmark):

[{l}]({a})

We look forward to seeing you!
          `,
    "welcome": `
### Registration for Family Day in Design!

**Dear Colleagues,**

We are delighted to invite you and your families to our Family Day in the Design department! The Family Day will take place **on September 21st from 12:00 to 16:00 in Building 80**. You can register via the form linked below.

Due to regulatory requirements, the number of participants is limited to 1000 people. **Therefore, the following rules apply for registration**:

- All employees of the design department are eligible to participate.
- Each employee may bring one guest and children.
- If you would like to bring more guests, you can indicate this on the registration form to potentially receive additional tickets.
- If tickets are still available after the first registration round, they will be raffled among those who would like to bring more guests.

We look forward to a wonderful day full of fun and shared experiences!

Your Family Day Team`,
    "form.password.label": "Password",
    "form.password.help": "Enter your password here",
    "form.name.label": "Name Design Employee",
    "form.name.placeholder": "e.g. Max Mustermann",
    "form.name.help": "Enter your name here",
    "form.email.label": "Email Address",
    "form.email.placeholder": "e.g. max.mustermann@mercedes-benz.com",
    "form.email.help": "Enter your email address here",
    "form.userid.label": "User Id",
    "form.userid.placeholder": "e.g. MMUSTER",
    "form.userid.help": "Enter your User Id here",
    "form.attending.label": "Attendance",
    "form.attending.yes": "I will attend the Family Day",
    "form.attending.no": "I cannot attend",
    "form.attending.help": "Select whether you can attend.",
    "form.bringguest.label": "I am bringing a guest",
    "form.bringguest.help":
      "Activate this switch if you want to bring a guest.",
    "form.guest.label": "Guest Name",
    "form.guest.placeholder": "e.g. Martina Mustermann",
    "form.guest.help": "Enter your guest's name here.",
    "form.extraguest.label": "Name of additional guest",
    "form.extraguest.placeholder": "e.g. Martin Mustermann",
    "form.extraguest.help":
      "Enter the name of your potential additional guest here.",
    "form.bringextraguest.label":
      "I would like to bring additional guests if possible",
    "form.bringextraguest.help":
      "Activate this switch if you would like to bring additional guests. If we do not reach the maximum number of visitors, we will raffle the remaining tickets among the persons listed here.",
    "form.bringextraguest.add": "add another guest",
    "form.bringchild.label": "I am bringing children",
    "form.bringchild.help":
      "Activate this switch if you want to bring children. If you want to remove a child, simply submit the form with an empty name.",
    "form.bringchild.add": "add another child",
    "form.children.label": "Child's Name",
    "form.children.placeholder": "e.g. Madlene Mustermann",
    "form.children.help": "Enter your child's name here.",
    "form.submit.label": "Continue",
    "form.responsible.label":
      "I take responsibility and supervision for my guests",
    "form.responsible.help":
      "As a host, you are responsible for ensuring that your guests adhere to the rules on our premises and are not unsupervised on the premises.",
    "form.fill.alert": "Please fill out all the formfields.",
    "form.responsible.alert":
      "Please confirm that you take responsibility and supervision for your guests.",
  },
};

export default en;