/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";
import { Button, ButtonSize, ButtonType } from "./Button.ts";
import state, { AppTheme } from "../../state.ts";
import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";

import ThemeDarkIcon from "../../assets/icons/moon.svg";
import ThemeLightIcon from "../../assets/icons/sun.svg";
import ThemeAutoIcon from "../../assets/icons/night-mode.svg";

let remove: CallableFunction | undefined = undefined;
const updateTheme = () => {
  if (remove != undefined) {
    remove();
  }
  const settings = state.settings();
  const html: HTMLHtmlElement | null = document.getElementsByTagName("html")[0];
  const media = matchMedia("(prefers-color-scheme: dark)");
  media.addEventListener("change", updateTheme);
  console.log(media.matches);
  remove = () => {
    media.removeEventListener("change", updateTheme);
  };
  if (html && AppTheme.Dark == settings.theme) {
    html.classList.add(AppTheme.Dark);
    html.classList.remove(AppTheme.Light);
  } else if (html && AppTheme.Light == settings.theme) {
    html.classList.add(AppTheme.Light);
    html.classList.remove(AppTheme.Dark);
  } else {
    html.classList.remove(AppTheme.Light);
    html.classList.remove(AppTheme.Dark);
    if (media.matches) {
      html.classList.add(AppTheme.Dark);
    }
  }
};

export interface ToolbarAttrs {
}

export class Toolbar implements ClassComponent<ToolbarAttrs> {
  public oncreate(_vnode: m.VnodeDOM<ToolbarAttrs, this>) {
    updateTheme();
  }
  view(vnode: Vnode<ToolbarAttrs, this>): Children {
    return m(".toolbar", {}, [
      vnode.children,
      m(Button,{
        type: ButtonType.OutlineDark,
          size: ButtonSize.SizeSquare,
          toolbar: true,
          title: state.settings().language == "en"
            ? "Wechsle zu Deutsch"
            : "Wechsle zu Englisch",
            onclick: () => {
              const settings = state.settings();
              if (settings.language == "en") {
                settings.language = "de";
              }else{
                settings.language = "en";
              }
              state.settings(settings);
              i18next.changeLanguage(settings.language, m.redraw);
            }
      }, state.settings().language == "en" ? "Deutsch"
      : "Englisch",),
      m(
        Button,
        {
          type: ButtonType.OutlineDark,
          size: ButtonSize.SizeSquare,
          toolbar: true,
          title: state.settings().theme == AppTheme.Light
            ? i18next.t("theme.light")
            : state.settings().theme == AppTheme.Dark
            ? i18next.t("theme.dark")
            : i18next.t("theme.auto"),
          onclick: () => {
            const settings = state.settings();
            if (settings.theme == AppTheme.Dark) {
              settings.theme = AppTheme.Auto;
            } else if (settings.theme == AppTheme.Auto) {
              settings.theme = AppTheme.Light;
            } else {
              settings.theme = AppTheme.Dark;
            }
            state.settings(settings);
            updateTheme();
          },
        },
        state.settings().theme == AppTheme.Light
          ? m.trust(ThemeLightIcon)
          : state.settings().theme == AppTheme.Dark
          ? m.trust(ThemeDarkIcon)
          : m.trust(ThemeAutoIcon),
      ),
    ]);
  }
}
