/// <reference lib="dom" />
import m, {
  Children,
  ClassComponent,
  Vnode,
} from "https://esm.sh/mithril@2.2.2";

export enum ButtonType {
  Default = "btn-default",
  Alternative = "btn-alternative",
  Ghost = "btn-ghost",
  Dark = "btn-dark",
  Light = "btn-light",
  Green = "btn-green",
  Red = "btn-red",
  Yellow = "btn-yellow",
  Purple = "btn-purple",
  OutlineDefault = "btn-outline-default",
  OutlineDark = "btn-outline-dark",
  OutlineGreen = "btn-outline-green",
  OutlineRed = "btn-outline-red",
  OutlineYellow = "btn-outline-yellow",
  OutlinePurple = "btn-outline-purple",
}

export enum ButtonSize {
  SizeXs = "size-xs",
  SizeSm = "size-sm",
  SizeM = "size-m",
  SizeLg = "size-lg",
  SizeXl = "size-xl",
  SizeIcon = "size-icon",
  SizeSquare = "size-square",
}

export interface ButtonAttrs {
  type?: ButtonType;
  title?: string;
  size?: ButtonSize;
  id?: string;
  disabled?: boolean;
  toolbar?: boolean;
  class?: string;
  onclick?: (event: MouseEvent) => undefined;
}
/**
 * A button
 */
export class Button implements ClassComponent<ButtonAttrs> {
  view(vnode: Vnode<ButtonAttrs, this>): Children {
    const buttonOpts: m.Attributes = {
      type: "button",
      class: "btn",
    };
    if (vnode.attrs.title) {
      buttonOpts.title = vnode.attrs.title;
    }
    if (vnode.attrs.type) {
      buttonOpts.class += " " + vnode.attrs.type;
    } else {
      buttonOpts.class += " " + ButtonType.Default;
    }
    if (vnode.attrs.size) {
      buttonOpts.class += " " + vnode.attrs.size;
    }
    if (vnode.attrs.toolbar) {
      buttonOpts.class += " " + "toolbar";
    }
    if (vnode.attrs.class) {
      buttonOpts.class += " " + vnode.attrs.class;
    }

    if (vnode.attrs.disabled) {
      buttonOpts["disabled"] = vnode.attrs.disabled;
    }

    if (vnode.attrs.onclick) {
      buttonOpts["onclick"] = vnode.attrs.onclick;
    }
    if (vnode.attrs.id) {
      buttonOpts["id"] = vnode.attrs.id;
    }
    return m("button", buttonOpts, vnode.children);
  }
}
