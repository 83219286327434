import Stream from "https://esm.sh/mithril@2.2.2/stream";
import PocketBase, { RecordModel } from "npm:pocketbase";
import m from "https://esm.sh/mithril@2.2.2";
import i18next from "https://deno.land/x/i18next@v23.11.5/index.js";
import { utf16Decode } from "npm:pdf-lib";

export type Guest = {
  name: string;
  id: string;
  owner: string;
  delete: boolean;
};

export type FormData = {
  employeeName: string;
  employeeEmail: string;
  employeeUserId: string;
  children: Array<Guest>;
  guest: Guest | undefined;
  extraguests: Array<Guest>;
  id: string;
  responsible: boolean;
  attending: boolean;
};

export enum AppTheme {
  Auto = "auto",
  Dark = "dark",
  Light = "light",
}

export type Settings = {
  theme: AppTheme;
  viewForm: boolean;
  language: string;
};

function createAppState() {
  const pb = new PocketBase();
  const isBusy = Stream<boolean>(false);

  const formData = Stream<FormData>({
    employeeName: "",
    employeeEmail: "",
    employeeUserId: "",
    guest: undefined,
    children: new Array<Guest>(),
    extraguests: new Array<Guest>(),
    attending: false,
    responsible: false,
    id: "",
  });

  const settings = Stream<Settings>({
    theme: AppTheme.Auto,
    viewForm: false,
    language: "de",
  });


  function isAuthenticated():boolean{
    return pb.authStore.isAdmin && pb.authStore.isValid;
  }

  function getToken():string{
    return pb.authStore.token;
  }

  function login(user: string, pass: string) {
    pb.admins.authWithPassword(user, pass).then((authData) => {
      console.log(authData);
    }).catch(
      (reason) => {
        console.warn(reason);
      },
    ).finally(() => {
      m.redraw();
    });
  }

  function logout() {
    pb.authStore.clear();
  }

  async function createGuest(guest: Guest, owner: string): Promise<string> {
    const data = {
      "name": guest.name,
      "owner": owner,
    };
    const record = await pb.collection("ticket_request").create(data, {
      requestKey: null,
    });
    return record.id;
  }

  async function commitChanges() {
    isBusy(true);
    const fd = formData();
    let user: RecordModel;
    try {
      if (fd.id == "") {
        let emailUnique = false;
        try {
          const record = await pb.collection("employees").getFirstListItem(
            pb.filter("email = {:email}", { email: fd.employeeEmail }),
            {
              fields: "email",
            },
          );
          alert(`${i18next.t("error.email.unique")}  ${record.email}`);
        } catch (_error) {
          emailUnique = true;
        }
        if (!emailUnique) {
          m.route.set("/");
          throw new Error("email is not unique");
        }
        const data = {
          "email": fd.employeeEmail,
          "name": fd.employeeName,
          "userid": fd.employeeUserId,
          "attending": fd.attending,
          "responsible": fd.responsible,
        };
        user = await pb.collection("employees").create(data);
        fd.id = user.id;
      } else {
        const data = {
          "email": fd.employeeEmail,
          "name": fd.employeeName,
          "userid": fd.employeeUserId,
          "attending": fd.attending,
          "responsible": fd.responsible,
        };
        user = await pb.collection("employees").update(fd.id, data);
      }

      if (fd.guest && fd.guest.id == "" && fd.guest.delete == false) {
        // create guest if needed
        const guestid = await createGuest(fd.guest, fd.id);
        fd.guest.id = guestid;
        const user = await pb.collection("employees").update(fd.id, {
          "guest": guestid,
        });
        if (user.guest !== guestid) {
          console.warn("error syncing guest");
        }
      } else if (fd.guest && fd.guest.id !== "" && fd.guest.delete) {
        // delete guest
        await pb.collection("ticket_request").delete(fd.guest.id);
        fd.guest = undefined;
        await pb.collection("employees").update(fd.id, {
          "guest": "",
        });
      } else if (fd.guest && fd.guest.id !== "") {
        // update guest
        await pb.collection("ticket_request").update(fd.guest.id, {
          "name": fd.guest.name,
        });
      }

      // EXTRAGUESTS
      // deleting extra guests
      await Promise.all(fd.extraguests.map(async (guest) => {
        if ((guest.delete || guest.name.trim().length == 0) && guest.id != "") {
          await pb.collection("ticket_request").delete(guest.id);
        }
        return guest;
      }));
      fd.extraguests = fd.extraguests.filter((
        g,
      ) => (g.delete == false && g.name.trim().length > 0));
      // creating new extra guests & updating
      await Promise.all(fd.extraguests.map(async (guest) => {
        if (!guest.delete && guest.id == "") {
          const guestid = await createGuest(guest, fd.id);
          guest.id = guestid;
        } else if (!guest.delete && guest.id != "") {
          await pb.collection("ticket_request").update(guest.id, {
            "name": guest.name,
          });
        }
        return guest;
      }));
      const extragids = fd.extraguests.map<string>((g) => g.id);
      await pb.collection("employees").update(fd.id, {
        "extraguests": extragids,
      });

      // Children
      // deleting children
      await Promise.all(fd.children.map(async (guest) => {
        if ((guest.delete || guest.name.trim().length == 0) && guest.id != "") {
          await pb.collection("ticket_request").delete(guest.id);
        }
        return guest;
      }));
      fd.children = fd.children.filter((
        g,
      ) => (g.delete == false && g.name.trim().length > 0));
      // creating new children & updating
      await Promise.all(fd.children.map(async (guest) => {
        if (!guest.delete && guest.id == "") {
          const guestid = await createGuest(guest, fd.id);
          guest.id = guestid;
        } else if (!guest.delete && guest.id != "") {
          await pb.collection("ticket_request").update(guest.id, {
            "name": guest.name,
          });
        }
        return guest;
      }));
      const childids = fd.children.map<string>((g) => g.id);
      await pb.collection("employees").update(fd.id, {
        "children": childids,
      });
    } catch (error) {
      console.error(error);
    } finally {
      formData(fd);
      isBusy(false);
      m.redraw();
      m.route.set("/info/:token", { token: formData().id });
    }
  }

  function queryDataForToken(token: string) {
    isBusy(true);
    pb.collection("employees").getFirstListItem(
      pb.filter("id = {:token}", { token: token }),
      {
        expand: "guest,children,extraguests",
      },
    ).then((record) => {
      console.log("queryDataForToken", record);
      const fd = formData();
      fd.employeeName = record.name;
      fd.employeeEmail = record.email;
      fd.employeeUserId = record.userid;
      fd.attending = record.attending;
      fd.responsible = record.responsible;
      if (record.guest == "") {
        fd.guest = undefined;
      } else {
        if (record.expand) {
          fd.guest = {
            name: record.expand.guest.name,
            id: record.expand.guest.id,
            owner: record.id,
            delete: false,
          };
        }
      }
      fd.extraguests = new Array<Guest>();
      if (record.extraguests.length > 0) {
        if (record.expand) {
          fd.extraguests = record.expand.extraguests.map((g) => {
            return {
              name: g.name,
              id: g.id,
              owner: record.id,
              delete: false,
            };
          });
        }
      }
      fd.children = new Array<Guest>();
      if (record.children.length > 0) {
        if (record.expand) {
          fd.children = record.expand.children.map((g) => {
            return {
              name: g.name,
              id: g.id,
              owner: record.id,
              delete: false,
            };
          });
        }
      }
      fd.id = record.id;
      formData(fd);
    }).catch((reason) => {
      console.warn(reason);
    }).finally(() => {
      isBusy(false);
      m.redraw();
    });
  }

  return {
    formData,
    settings,
    isAuthenticated,
    isBusy,
    queryDataForToken,
    commitChanges,
    getToken,
    login,
    logout,
  };
}

const state = createAppState();

export default state;
